import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import SavedVideosSaveNewVideoModalBody from './SavedVideosSaveNewVideoModalBody.jsx';
import useSaveVideoMutation from './useSaveVideoMutation.js';
import { savedVideosFetch } from '../../../../actions/savedVideos';
import translate from '../../../../helpers/translate';
import { getCurrentProjectId } from '../../../../selectors/forms';
import { getSavedVideosPageSize } from '../../../../selectors/savedCoverage/savedVideos';
import { getNotLockedTags } from '../../../../selectors/tags';

const SavedVideosSaveNewVideoModal = ({ isOpen, onClose, tags, savedVideosFetch, projectId, sizePerPage }) => {
  const [videoURL, setVideoURL] = useState(null);
  const [isUrlValid, setIsUrlValid] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const onSuccess = () => {
    onClose();
    savedVideosFetch({ project_id: projectId, pagination: { sizePerPage, page: 1, sortField: 'published_at' } });
  };

  const mutation = useSaveVideoMutation(onSuccess);

  useEffect(
    () => () => {
      setVideoURL(null);
      setSelectedTags([]);
      setIsUrlValid(false);
    },
    [],
  );

  const handleMutation = () => {
    mutation.mutate({ youtube_video_url: videoURL, tag_id: selectedTags });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="md">
      <ModalHeader className="fp-modal-header--small">
        {translate('pages.saved_videos.table.save_new_video.title')}
      </ModalHeader>
      <ModalBody className="fp-modal-body">
        <SavedVideosSaveNewVideoModalBody
          setIsUrlValid={setIsUrlValid}
          setVideoURL={setVideoURL}
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
          isUrlValid={isUrlValid}
          videoURL={videoURL}
          tags={tags}
        />
      </ModalBody>
      <ModalFooter>
        <Button size="sm" onClick={onClose} className="fp-button-close">
          {translate('common.close')}
        </Button>
        <Button
          size="sm"
          type="submit"
          color="primary"
          name="saveButton"
          disabled={!selectedTags?.length && !videoURL}
          onClick={handleMutation}
        >
          {mutation.isLoading ? translate('common.loading') : translate('common.save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  tags: getNotLockedTags(state),
  projectId: getCurrentProjectId(state),
  sizePerPage: getSavedVideosPageSize(state),
});

export default connect(mapStateToProps, {
  savedVideosFetch,
})(SavedVideosSaveNewVideoModal);
