import cloneDeep from 'lodash/cloneDeep';

import lateralMenu from '../menuConfig';
import { getOrganizationId } from '../selectors/organizations';
import { getOrganizationPermissions } from '../selectors/permissions';
import { store } from '../store/store';

const getPermissionsBasedMenu = () => {
  const state = store.getState();
  const organizationId = getOrganizationId(state);

  const organizationPermissions = getOrganizationPermissions(state);
  let clonedMenu = cloneDeep(lateralMenu);

  if (!organizationPermissions?.youtube_feed) {
    const mediaFeeds = clonedMenu.find((item) => item.name === 'Media Feeds');
    const savedCoverage = clonedMenu.find((item) => item.name === 'Saved Coverage');

    if (mediaFeeds) {
      mediaFeeds.children = mediaFeeds.children.filter((child) => child.name !== 'Youtube');
    }

    if (savedCoverage) {
      savedCoverage.children = savedCoverage.children.filter((child) => child.name !== 'Videos');
    }
  }

  if (!organizationPermissions.twitch_feed) {
    const mediaFeeds = clonedMenu.find((item) => item.name === 'Media Feeds');
    const savedCoverage = clonedMenu.find((item) => item.name === 'Saved Coverage');

    if (mediaFeeds) {
      mediaFeeds.children = mediaFeeds.children.filter((child) => child.name !== 'Twitch');
    }

    if (savedCoverage) {
      savedCoverage.children = savedCoverage.children.filter((child) => child.name !== 'Streams');
    }
  }

  if (!organizationPermissions?.data) {
    clonedMenu = clonedMenu.filter((item) => item.name !== 'Super Search');
  }

  if (organizationId !== 1) {
    clonedMenu = clonedMenu.filter((item) => item.name !== 'Websites');
  }

  return clonedMenu;
};

export default getPermissionsBasedMenu;
