/**
 * Processes search query terms by trimming whitespace.
 *
 * @param {string|array} searchQuery - The search query or array of query terms.
 * - If `searchQuery` is a string, it's assumed to be a comma-separated list of terms.
 * - If `searchQuery` is an array, it's assumed to be an array of terms.
 *
 * @returns {array} An array of processed terms where each term has been trimmed of whitespace.
 * - If a string was provided, it splits the string by commas into an array of terms, then trims each.
 * - If an array was provided, it simply trims each term in the array.
 */
const processTerms = (searchQuery) => {
  if (Array.isArray(searchQuery)) {
    return searchQuery.map((term) => term.trim());
  } else {
    return searchQuery.split(',').map((term) => term.trim());
  }
};

/**
 * Creates a query string where all terms are either required (included) or excluded based on the specified flag.
 *
 * @param {string|array} searchQuery - The search query or array of query terms to be processed.
 *   If a string is provided, it's assumed to be comma-separated.
 *   If an array is provided, it's assumed to be an array of terms.
 * @param {boolean} isExclude - Flag indicating whether the terms should be excluded.
 *   If true, all terms are prefixed with '-' to indicate exclusion.
 *   If false, all terms are prefixed with '+' to indicate they are required.
 *
 * @returns {string} A query string where:
 *   - Each term is quoted if not already.
 *   - Terms are prefixed with '+' or '-' depending on the `isExclude` flag.
 *   - If there's only one term and it's not for exclusion, it's prefixed with '+'.
 *   - If there's only one term and it's for exclusion, it's prefixed with '-'.
 *   - For multiple terms, they are joined by a space, each prefixed accordingly based on `isExclude`.
 */
const createAllQuery = (searchQuery, isExclude) => {
  const terms = processTerms(searchQuery);

  if (terms.length === 1 && !isExclude) {
    return `+"${terms[0]}"`;
  } else if (terms.length === 1 && isExclude) {
    return `-"${terms[0]}"`;
  } else {
    const requiredTerms = terms.map((term) => {
      const quotedTerm = term.startsWith('"') && term.endsWith('"') ? term : `"${term}"`;
      return isExclude ? `-${quotedTerm}` : `+${quotedTerm}`;
    });
    return requiredTerms.join(' ');
  }
};

/**
 * Creates a query where any of the specified search terms are sufficient for a match. Terms are processed to ensure
 * they are properly quoted, and the query is formatted to indicate whether terms are required or excluded.
 *
 * @param {string | string[]} searchQuery - The search query or an array of search terms to be included or excluded.
 * @param {boolean} isExclude - Indicates if the terms should be excluded from the search results.
 * @returns {string} A formatted query string where terms are included with a "+" sign for inclusion or "-" for exclusion.
 *                   If multiple terms are provided, they are combined with commas and enclosed in parentheses.
 */
const createAnyQuery = (searchQuery, isExclude) => {
  const terms = processTerms(searchQuery);

  if (terms.length === 1 && !isExclude) {
    return `+"${terms[0]}"`;
  } else if (terms.length === 1 && isExclude) {
    return `-"${terms[0]}"`;
  } else {
    const quotedTerms = terms.map((term) => (term.startsWith('"') && term.endsWith('"') ? term : `"${term}"`));
    const query = quotedTerms.join(', ');
    return `${isExclude ? '-' : '+'}(${query})`;
  }
};

/**
 * Automatically wraps each term from the input search query in double quotes.
 * This function is useful for ensuring that each term in the query is treated as a distinct string,
 * especially in contexts where exact matches are required.
 *
 * @param {string} searchQuery - The input search query. It's expected to be a comma-separated string
 *                               where each term that needs to be quoted is separated by a comma.
 * @returns {string} A new string where each term from the input has been trimmed of whitespace
 *                   and wrapped in double quotes, then all quoted terms are joined back together
 *                   into a single string, separated by commas.
 *
 * Example:
 *   Given an input searchQuery of 'term1, term2, term3',
 *   the function returns '"term1", "term2", "term3"'.
 */
const autoQuoteTerms = (searchQuery) => {
  const terms = searchQuery.split(',').map((term) => term.trim());
  const quotedTerms = terms.map((term) => `"${term}"`);
  return quotedTerms.join(', ');
};

/**
 * Formats a search query based on specified criteria, such as whether all terms must match ('all'),
 * any term may match ('any'), or if terms are simply to be quoted without additional logic.
 * This function acts as a high-level interface to format search queries by delegating to more specific functions
 * based on the given type and exclusion flag.
 *
 * @param {string | string[]} searchQuery - The original search query or an array of search terms.
 *                                          It can be a single string with terms separated by commas or an array of individual terms.
 * @param {string|null} type - The type of query to create. This can be 'all', 'any', or null.
 *                             'all' indicates that all terms must be included (or excluded) for a match.
 *                             'any' indicates that any of the terms may match for a successful query.
 *                             If null, terms will simply be quoted without forming a more complex query.
 * @param {boolean} isExclude - Indicates whether the terms should be formatted for exclusion.
 *                              If true, terms will be prefixed with '-' to indicate they should be excluded in the search.
 *                              If false, terms will be prefixed with '+' or simply quoted, indicating inclusion.
 * @returns {string} A formatted search query string that can be used directly in searches.
 *                   The format of the returned string depends on the type and isExclude parameters.
 *
 * Examples:
 * - Given a single term without exclusion and type 'all': queryBuilderFormatter('term', 'all', false)
 *   might return `+"term"`, indicating the term must be included.
 * - Given multiple terms with exclusion and type 'any': queryBuilderFormatter('term1, term2', 'any', true)
 *   might return `-("term1", "term2")`, indicating that results should not include these terms.
 */
export const queryBuilderFormatter = (searchQuery, type = null, isExclude = false) => {
  if (type === 'all') {
    return createAllQuery(searchQuery, isExclude);
  } else if (type === 'any') {
    return createAnyQuery(searchQuery, isExclude);
  } else {
    return autoQuoteTerms(searchQuery);
  }
};
const processGroupedTerms = (terms) =>
  terms
    .split(',')
    .map((term) => term.trim())
    .map((term) => term.replace(/^"|"$/g, ''));

const getIsAdvancedQuery = (str) => {
  const complexPatterns = [
    /(\+\s*\([^)]+\)\s*){2,}/,
    /(-\s*\([^)]+\)\s*){2,}/,
    /\+("[^"]+"|\S+)\s*\+\s*\(([^)]+)\)/,
    /-\(.*OR.*\)/,
    /\+\s*"[^"]+"\s*-\s*\([^)]+\)\s*\+\s*\(/,
    /(\sOR\s.*\sAND\s)|(\sAND\s.*\sOR\s)/,
  ];

  return complexPatterns.some((pattern) => pattern.test(str));
};
const getTermsFromSimpleQuery = (str) => str.match(/"([^"]+)"/g).map((term) => term.replace(/^"|"$/g, ''));
const getTermsFromORQuery = (str) =>
  str.split(/\s+OR\s+/).flatMap((term) => term.split(/"\s*"\s*/).map((t) => t.replace(/^"|"$/g, '').trim()));
const getTermsFromANDQuery = (str) => str.split(/\s+AND\s+/).map((term) => term.replace(/^"|"$/g, ''));

export const reverseQueryToObject = (str) => {
  if (!str) {
    return {
      include: [],
      exclude: [],
      includeType: 'all',
      excludeType: 'all',
      isAdvanced: false,
    };
  }

  const hasGroupedTermsWithPlus = /\+\s*\(/.test(str);
  const multiplePlusesOutsideGroups = (str.match(/\+/g) || []).length > 1;

  const isAdvanced = getIsAdvancedQuery(str) || (hasGroupedTermsWithPlus && multiplePlusesOutsideGroups);
  const hasBothOrAnd = str.includes(' OR ') && str.includes(' AND ');
  const hasOrAndSymbols = str.includes(' OR ') && (str.includes('+(') || str.includes('-('));

  if (isAdvanced || hasBothOrAnd || hasOrAndSymbols) {
    return {
      include: [],
      exclude: [],
      includeType: 'all',
      excludeType: 'all',
      isAdvanced: true,
    };
  }

  let includeTerms = [],
    excludeTerms = [];

  let includeType = 'all';
  let excludeType = 'all';
  const termPattern = /([+-])?\s*"([^"]+)"|([+-])\(\s*"([^"]+)"(?:\s*,\s*"([^"]+)")*\s*\)/g;
  let match;
  while ((match = termPattern.exec(str)) !== null) {
    const [, individualPrefix, individualTerm, groupedPrefix, ...groupedTerms] = match.filter((m) => m !== undefined);

    if (individualTerm) {
      if (individualPrefix === '-') {
        excludeTerms.push(individualTerm);
      } else {
        includeTerms.push(individualTerm);
      }
    } else if (groupedTerms.length) {
      const processedTerms = groupedTerms.map((term) => term.replace(/^"|"$/g, ''));
      if (groupedPrefix === '-') {
        excludeTerms.push(...processedTerms);
      } else {
        includeTerms.push(...processedTerms);
      }
    }
  }

  const isSimpleQuery =
    !str.includes(' OR ') &&
    !str.includes(' AND ') &&
    !str.includes(' +') &&
    !str.includes(' -') &&
    /^"([^"]+)"(\s+"([^"]+)")*$/.test(str);

  if (isSimpleQuery) {
    includeTerms = getTermsFromSimpleQuery(str);
  } else if (str.includes(' OR ')) {
    includeTerms = getTermsFromORQuery(str);
    includeType = 'any';
  } else if (str.includes(' AND ')) {
    includeTerms = getTermsFromANDQuery(str);
    includeType = 'all';
  }

  const individualTermPattern = /([+-])"([^"]+)"/g;
  const groupedTermPattern = /([+-])\(([^)]+)\)/g;

  str.replace(individualTermPattern, (match, prefix, term) => {
    if (prefix === '+') {
      includeTerms.push(term);
    } else if (prefix === '-') {
      excludeTerms.push(term);
    }
  });

  str.replace(groupedTermPattern, (match, prefix, terms) => {
    let reversedTerms = processGroupedTerms(terms);
    if (prefix === '+') {
      includeTerms = includeTerms.concat(reversedTerms);
      includeType = 'any';
    } else {
      excludeTerms = excludeTerms.concat(reversedTerms);
      excludeType = 'any';
    }
  });

  return {
    include: [...new Set(includeTerms)],
    exclude: [...new Set(excludeTerms)],
    includeType: includeType,
    excludeType: excludeType,
    isAdvanced: isAdvanced,
  };
};
