import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import columns from './columns.jsx';
import YoutubeFeedEditFeedButton from './YoutubeFeedEditFeedButton.jsx';
import YoutubeFeedTableActions from './YoutubeFeedTableActions.jsx';
import { fetchYoutubeFeed, youtubeFeedClean, youtubeFeedTableFiltersClean } from '../../../actions/youtubeFeed';
import Table from '../../../components/ICO/Table/Table.jsx';
import translate from '../../../helpers/translate.js';
import {
  getYoutubeFeed,
  getYoutubeFeedPagination,
  getYoutubeFeedTableFilters,
  getYoutubeFeedCurrentKeyword,
  getYoutubeFeedIsLoading,
} from '../../../selectors/feeds/youtubeFeed';
import { getOrganizationId } from '../../../selectors/organizations';
import YoutubeFeedTableFilters from '../Filters/YoutubeFeedTableFilters.jsx';

const YoutubeFeedTable = ({
  project,
  fetchYoutubeFeed,
  youtubeFeedClean,
  filters,
  data = [],
  pagination,
  youtubeFeedTableFiltersClean,
  userOrganizationId,
  currentKeyword,
  isLoading,
}) => {
  const youtube_search_id = project['youtube-search-id'];
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    fetchYoutubeFeed({
      youtube_search_id,
      pagination: {
        page: 1,
        sizePerPage: 10,
        sortField: 'published_at',
      },
      filters: {
        organization_id: userOrganizationId,
      },
    });
    return () => {
      youtubeFeedClean();
      youtubeFeedTableFiltersClean();
    };
  }, [youtube_search_id, fetchYoutubeFeed, youtubeFeedClean, youtubeFeedTableFiltersClean, userOrganizationId]);

  const onTableChange = (type, { searchText, page, sortField, sortOrder }) => {
    if (type === 'search' && searchText) {
      youtubeFeedClean();
      fetchYoutubeFeed({
        youtube_search_id: project['youtube-search-id'],
        filters: {
          ...filters,
          searchTerm: searchText,
          organization_id: userOrganizationId,
        },
        pagination: {
          page,
          pageSize,
          sortField,
          sortOrder,
        },
      });
    } else if (searchText) {
      fetchYoutubeFeed({
        youtube_search_id: project['youtube-search-id'],
        filters: {
          ...filters,
          searchTerm: searchText,
          organization_id: userOrganizationId,
        },
        pagination: {
          page,
          pageSize,
          sortField,
          sortOrder,
        },
      });
    } else {
      fetchYoutubeFeed({
        youtube_search_id: project['youtube-search-id'],
        filters: {
          ...filters,
          organization_id: userOrganizationId,
        },
        pagination: {
          page,
          pageSize,
          sortField,
          sortOrder,
        },
      });
    }
  };

  const onSizeChange = (newSize) => {
    setPageSize(newSize);
    fetchYoutubeFeed({
      youtube_search_id: project['youtube-search-id'],
      filters: {
        ...filters,
        organization_id: userOrganizationId,
      },
      pagination: {
        page: 1,
        pageSize: newSize,
      },
    });
  };

  const tableFilters = <YoutubeFeedTableFilters />;

  const paginationWithExtra = {
    ...pagination,
    pageSize,
    onSizeChange,
  };

  return (
    <Table
      columns={columns}
      data={data}
      onTableChange={onTableChange}
      title={`${project.name} Videos`}
      subtitle={currentKeyword ? `${translate('pages.youtube_feed.subtitle')} ${currentKeyword}` : null}
      rowActions={<YoutubeFeedTableActions />}
      filters={tableFilters}
      pagination={paginationWithExtra}
      isLoading={isLoading}
      addButton={<YoutubeFeedEditFeedButton />}
      withRowSelection
    />
  );
};

const mapStateToProps = (state) => ({
  data: getYoutubeFeed(state),
  filters: getYoutubeFeedTableFilters(state),
  pagination: getYoutubeFeedPagination(state),
  userOrganizationId: getOrganizationId(state),
  currentKeyword: getYoutubeFeedCurrentKeyword(state),
  isLoading: getYoutubeFeedIsLoading(state),
});

export default connect(mapStateToProps, {
  fetchYoutubeFeed,
  youtubeFeedClean,
  youtubeFeedTableFiltersClean,
})(YoutubeFeedTable);
