import { combineReducers } from 'redux';

import { STATUS_ENABLED } from '../actions/forms';
import {
  CREATE_MODULE,
  CHANGE_MODULE,
  CHANGE_PAGE,
  CHANGE_PAGE_SIZE,
  CHANGE_CHART,
  CHANGE_MODULE_STATUS,
} from '../actions/modules';

const base = (
  state = {
    items: {
      alertsTable: {
        pagination: {
          current_page: 0,
          page_size: 25,
        },
      },
      articlesTable: {
        pagination: {
          current_page: 1,
          page_size: 25,
        },
      },
      articlesReportTable: {
        pagination: {
          current_page: 1,
          page_size: 25,
        },
      },
      articlesWebsiteTable: {
        pagination: {
          current_page: 1,
          page_size: 5000,
        },
      },
      articlesNewReportTable: {
        pagination: {
          current_page: 1,
          page_size: 25,
        },
      },
      articlesFilteredTable: {
        pagination: {
          current_page: 1,
          page_size: 25,
        },
      },
      articlesFeedTable: {
        pagination: {
          current_page: 1,
          page_size: 25,
        },
        status: STATUS_ENABLED,
        notification: [],
      },
      websitesTable: {
        pagination: {
          current_page: 1,
          page_size: 25,
        },
      },
      articlesChart: {
        reach_values: false,
        visits_values: false,
        articles_values: true,
      },
      articlesLangChart: {
        reach_values: false,
        visits_values: false,
        articles_values: true,
      },
      articlesTagChart: {
        reach_values: false,
        visits_values: false,
        articles_values: true,
      },
      articlesAlertChart: {
        alertIds: [],
        reach_values: false,
        visits_values: false,
        articles_values: true,
      },
      newReportForm: {
        generatedLink: '',
        previewGenerated: false,
      },
      loadingModule: {
        isLoading: false,
        fetchStarted: false,
        fetchCompleted: false,
      },
    },
    itemsStatus: {},
  },
  action,
) => {
  switch (action.type) {
    case CREATE_MODULE:
    case CHANGE_MODULE_STATUS:
      return {
        items: {
          ...state.items,
          [action.payload.moduleId]: {
            ...state.items[action.payload.moduleId],
            status: action.payload.newStatus,
          },
        },
        itemsStatus: {
          ...state.itemsStatus,
        },
      };
    case CHANGE_MODULE:
      return {
        items: {
          ...state.items,
          [action.payload.moduleId]: action.payload.value,
        },
        itemsStatus: {
          ...state.itemsStatus,
        },
      };
    case CHANGE_CHART:
      return Object.assign(
        { ...state },
        {
          items: Object.assign(
            { ...state.items },
            {
              [action.payload.module]: {
                ...state.items[action.payload.module],
                ...action.payload.newValues,
              },
            },
          ),
        },
      );
    case CHANGE_PAGE:
      return Object.assign(
        { ...state },
        {
          items: Object.assign(
            { ...state.items },
            {
              [action.payload.module]: {
                pagination: {
                  ...state.items[action.payload.module].pagination,
                  current_page: action.payload.newPage,
                },
              },
            },
          ),
        },
      );
    case CHANGE_PAGE_SIZE:
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.module]: {
            pagination: {
              ...state.items[action.payload.module].pagination,
              page_size: action.payload.newPageSize,
            },
          },
        },
      };
    case 'RESET_ARTICLES_FEED_TABLE_FILTER_FORM':
      return {
        ...state,
        items: {
          ...state.items,
          articlesFeedTable: {
            pagination: {
              current_page: 1,
              page_size: 25,
            },
            status: STATUS_ENABLED,
            notification: [],
          },
        },
      };
    default:
      return state;
  }
};

export default combineReducers({
  modules: base,
});
