import React, { memo, useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';

import TwitchFeedTableBulkActions from './BulkActions/TwitchFeedTableBulkActions.jsx';
import columns from './columns.jsx';
import TwitchFeedTableFilters from './Filters/TwitchFeedTableFilters.jsx';
import TwitchFeedEditFeedButton from './TwitchFeedEditFeedButton.jsx';
import { fetchTwitchFeed, twitchFeedClean } from '../../../actions/twitchFeed';
import Table from '../../../components/ICO/Table/Table.jsx';
import translate from '../../../helpers/translate.js';
import {
  getTwitchFeed,
  getTwitchFeedIsLoading,
  getTwitchFeedPagination,
  getTwitchFeedTableFilters,
  getTwitchFeedCurrentKeyword,
} from '../../../selectors/feeds/twitchFeed';
import { getCurrentProject } from '../../../selectors/projects';

const TwitchFeedTable = ({
  data = [],
  pagination,
  isLoading,
  fetchTwitchFeed,
  project,
  twitchFeedClean,
  filters,
  currentKeyword,
}) => {
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    fetchTwitchFeed({
      twitch_search_id: project['twitch-search-id'],
      pagination: { page: 1, sizePerPage: 10, sortField: 'published_at' },
    });

    return () => {
      twitchFeedClean();
    };
  }, [fetchTwitchFeed, project, twitchFeedClean]);

  const onTableChange = useCallback(
    (type, { searchText, page, sortField, sortOrder }) => {
      if (type === 'search' && searchText) {
        twitchFeedClean();
        fetchTwitchFeed({
          twitch_search_id: project['twitch-search-id'],
          filters: {
            ...filters,
            searchTerm: searchText,
          },
          pagination: {
            page,
            pageSize,
            sortField,
            sortOrder,
          },
        });
      } else if (searchText) {
        fetchTwitchFeed({
          twitch_search_id: project['twitch-search-id'],
          filters: {
            ...filters,
            searchTerm: searchText,
          },
          pagination: {
            page,
            pageSize,
            sortField,
            sortOrder,
          },
        });
      } else {
        fetchTwitchFeed({
          twitch_search_id: project['twitch-search-id'],
          filters,
          pagination: {
            page,
            pageSize,
            sortField,
            sortOrder,
          },
        });
      }
    },
    [fetchTwitchFeed, twitchFeedClean, project, filters, pageSize],
  );

  const onSizeChange = (newSize) => {
    setPageSize(newSize);
    fetchTwitchFeed({
      twitch_search_id: project['twitch-search-id'],
      filters,
      pagination: {
        page: 1,
        pageSize: newSize,
      },
    });
  };

  const paginationWithExtra = {
    ...pagination,
    pageSize,
    onSizeChange,
  };

  return (
    <Table
      data={data}
      columns={columns}
      pagination={paginationWithExtra}
      title={`${project.name} Streams`}
      subtitle={currentKeyword ? `${translate('pages.twitch_feed.subtitle')} ${currentKeyword}` : null}
      onTableChange={onTableChange}
      addButton={<TwitchFeedEditFeedButton />}
      rowActions={<TwitchFeedTableBulkActions />}
      isLoading={isLoading}
      withRowSelection
      filters={<TwitchFeedTableFilters />}
    />
  );
};

const mapStateToProps = (state) => ({
  data: getTwitchFeed(state),
  isLoading: getTwitchFeedIsLoading(state),
  pagination: getTwitchFeedPagination(state),
  project: getCurrentProject(state),
  filters: getTwitchFeedTableFilters(state),
  currentKeyword: getTwitchFeedCurrentKeyword(state),
});

export default memo(connect(mapStateToProps, { fetchTwitchFeed, twitchFeedClean })(TwitchFeedTable));
