import React, { Fragment, memo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ChangeProjectButton from './ChangeProjectButton';
import { changeGlobalForm, tableActions } from '../../../actions/forms';
import { changePage } from '../../../actions/modules';
import { getClients } from '../../../selectors/clients';
import { getCurrentProject, getProjectsLocked } from '../../../selectors/projects';
import { getUsersCurrentUserDefaultProjectId } from '../../../selectors/users';
import Flex from '../../common/Flex';

const sortProjectsByName = (projects) => projects.sort((a, b) => a.name.localeCompare(b.name));

const ProjectsPanelBody = ({ projects, currentProject, onClick, defaultProjectId }) =>
  projects.map((project) => (
    <Fragment key={project.id}>
      <Flex className="justify-content-md-between">
        <ChangeProjectButton
          onClick={onClick}
          project={project}
          isActive={project.id === currentProject.id}
          isDefaultProject={defaultProjectId && project.id === defaultProjectId.toString()}
        />
      </Flex>
      <hr />
    </Fragment>
  ));

const mapStateToProps = (state) => ({
  currentProject: getCurrentProject(state),
  projects: sortProjectsByName(getProjectsLocked(state, false)),
  clients: getClients(state),
  content: state.forms.sidePanelModal.content,
  defaultProjectId: getUsersCurrentUserDefaultProjectId(state),
});

const mapDispatchToProps = (dispatch) => ({
  onClick: (projectId) => {
    dispatch(changeGlobalForm({ projectId }));
    dispatch(changePage('articlesFeedTable', 1));
    dispatch(changePage('articlesTable', 1));
    dispatch(tableActions['articlesFeedTable'].changeForm({ field: 'checkedRows', value: [] }));
    dispatch(tableActions['articlesFeedTable'].changeForm({ field: 'checkedRows', value: [] }));
    dispatch(tableActions['articlesFeedTable'].resetFilterForm());
    dispatch(tableActions['articlesTable'].resetFilterForm());
  },
});

ProjectsPanelBody.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  currentProject: PropTypes.shape({
    id: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(ProjectsPanelBody));
