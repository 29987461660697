import React from 'react';

import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import useUpdateKeyword from './useUpdateKeyword';
import translate from '../../../../helpers/translate';
import {
  getYoutubeFeedAreNotificationsEnabled,
  getYoutubeFeedDiscordWebhookMinSubscribers,
  getYoutubeFeedDiscordWebhookURL,
  getYoutubeFeedSlackWebhookMinSubscribers,
  getYoutubeFeedSlackWebhookURL,
} from '../../../../selectors/feeds/youtubeFeed';
import { getCurrentProject } from '../../../../selectors/projects';

const YoutubeFeedEditFeedModalFooter = ({
  onClose,
  keyword,
  project,
  previousKeyword,
  slackMinSubsNotification,
  slackWebhookUrl,
  discordWebhookUrl,
  discordMinSubsNotification,
  areNotificationsEnabled,
}) => {
  const mutation = useUpdateKeyword(onClose);
  const isButtonDisabled = keyword?.length < 3;

  const onClick = () => {
    let params = { youtube_search_id: project['youtube-search-id'] };

    if (keyword && keyword !== previousKeyword) {
      params = { ...params, keyword };
    }

    if (slackMinSubsNotification) {
      params = { ...params, slack_min_subs_notification: slackMinSubsNotification };
    }

    if (slackWebhookUrl) {
      params = { ...params, slack_webhook_url: slackWebhookUrl };
    }

    if (discordMinSubsNotification) {
      params = { ...params, discord_min_subs_notification: discordMinSubsNotification };
    }

    if (discordWebhookUrl) {
      params = { ...params, discord_webhook_url: discordWebhookUrl };
    }

    if (areNotificationsEnabled !== null) {
      params = { ...params, is_enabled: areNotificationsEnabled };
    }

    mutation.mutate(params);
    onClose();
  };

  return (
    <>
      <Button size="sm" onClick={onClose} className="fp-button-close">
        {translate('common.close')}
      </Button>
      <Button
        size="sm"
        type="submit"
        onClick={onClick}
        aria-label="save-button"
        color="primary"
        name="saveButton"
        disabled={isButtonDisabled}
      >
        {translate('common.update')}
      </Button>
    </>
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  slackWebhookUrl: getYoutubeFeedSlackWebhookURL(state),
  slackMinSubsNotification: getYoutubeFeedSlackWebhookMinSubscribers(state),
  discordWebhookUrl: getYoutubeFeedDiscordWebhookURL(state),
  discordMinSubsNotification: getYoutubeFeedDiscordWebhookMinSubscribers(state),
  areNotificationsEnabled: getYoutubeFeedAreNotificationsEnabled(state),
});

export default connect(mapStateToProps)(YoutubeFeedEditFeedModalFooter);
