import {
  SIGNUP_FREE_USER_CLEAR,
  SIGNUP_FREE_USER_CREATE_BRAND,
  SIGNUP_FREE_USER_CREATE_BRAND_ERROR,
  SIGNUP_FREE_USER_CREATE_BRAND_SUCCESS,
  SIGNUP_FREE_USER_CREATE_PROJECT,
  SIGNUP_FREE_USER_CREATE_PROJECT_ERROR,
  SIGNUP_FREE_USER_CREATE_PROJECT_SUCCESS,
  SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER,
  SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER_ERROR,
  SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER_SUCCESS,
  SIGNUP_FREE_USER_CREATE_USER_ACCOUNT,
  SIGNUP_FREE_USER_CREATE_USER_ACCOUNT_ERROR,
  SIGNUP_FREE_USER_CREATE_USER_ACCOUNT_SUCCESS,
} from './actionTypes';
import { apiFetch, defaultRequestOptions } from '../config/api-service';
import { COVERAGE_API_ROOT } from '../config/global-config';

export const signupFreeUserCreateBrand = ({ name, org_id }) => {
  const endpoint = 'free_user';
  const actions = {
    request: {
      type: SIGNUP_FREE_USER_CREATE_BRAND,
      payload: { name, org_id },
    },
    receive: SIGNUP_FREE_USER_CREATE_BRAND_SUCCESS,
    reject: SIGNUP_FREE_USER_CREATE_BRAND_ERROR,
  };

  const body = JSON.stringify({
    data: {
      type: 'free_user_client',
      attributes: {
        name,
        org_id,
      },
    },
  });

  const options = {
    method: 'POST',
    urlRoot: COVERAGE_API_ROOT,
    headers: defaultRequestOptions().headers,
  };

  return apiFetch(endpoint, actions, { body }, options);
};

export const signupFreeUserCreateProject = ({ name, client_id, organization_id }) => {
  const endpoint = 'free_user';
  const actions = {
    request: {
      type: SIGNUP_FREE_USER_CREATE_PROJECT,
      payload: { name, client_id, organization_id },
    },
    receive: SIGNUP_FREE_USER_CREATE_PROJECT_SUCCESS,
    reject: SIGNUP_FREE_USER_CREATE_PROJECT_ERROR,
  };

  const body = JSON.stringify({
    data: {
      type: 'free_user_project',
      attributes: {
        name,
        client_id,
        organization_id,
      },
    },
  });

  const options = {
    method: 'POST',
    urlRoot: COVERAGE_API_ROOT,
    headers: defaultRequestOptions().headers,
  };

  return apiFetch(endpoint, actions, { body }, options);
};

export const signupFreeUserCreateUser = ({ name, email, password, organization_name }) => {
  const endpoint = 'free_user';
  const actions = {
    request: {
      type: SIGNUP_FREE_USER_CREATE_USER_ACCOUNT,
      payload: { name, email, password, organization_name },
    },
    receive: SIGNUP_FREE_USER_CREATE_USER_ACCOUNT_SUCCESS,
    reject: SIGNUP_FREE_USER_CREATE_USER_ACCOUNT_ERROR,
  };

  const body = JSON.stringify({
    data: {
      type: 'free_user_account',
      attributes: {
        name,
        email,
        password,
        picture: null,
        organization_name,
      },
    },
  });

  const options = {
    method: 'POST',
    urlRoot: COVERAGE_API_ROOT,
    headers: defaultRequestOptions().headers,
  };

  return apiFetch(endpoint, actions, { body }, options);
};

export const signupFreeUserCreateCustomer = ({ name, email, organization_id }) => {
  const endpoint = 'free_user';
  const actions = {
    request: {
      type: SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER,
      payload: { name, email },
    },
    receive: SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER_SUCCESS,
    reject: SIGNUP_FREE_USER_CREATE_STRIPE_CUSTOMER_ERROR,
  };

  const body = JSON.stringify({
    data: {
      type: 'free_user_create_customer',
      attributes: {
        name,
        email,
        organization_id,
      },
    },
  });

  const options = {
    method: 'POST',
    urlRoot: COVERAGE_API_ROOT,
    headers: defaultRequestOptions().headers,
  };

  return apiFetch(endpoint, actions, { body }, options);
};

export const signupFreeUserClear = () => ({
  type: SIGNUP_FREE_USER_CLEAR,
});
