import { createSelector } from 'reselect';

export const getModules = (state) => state.modules.modules.items;
export const getModule = (state, module = 'articlesTable') => state.modules.modules.items[module];
export const getModuleById = (moduleId) => (state) => state.modules.modules.items[moduleId];

export const getArticlesChart = createSelector([getModules], (modules) => modules.articlesChart);

export const getArticleLangChart = createSelector([getModules], (modules) => modules.articlesLangChart);

export const getNewReportModule = createSelector([getModules], (modules) => modules.newReportForm);

export const getArticleTagChart = createSelector([getModules], (modules) => modules.articlesTagChart);

export const getLoadingModule = createSelector([getModules], (modules) => modules.loadingModule);

export const getModuleArticlesTable = createSelector(getModules, (modules) => modules.articlesTable);
