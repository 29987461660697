import React from 'react';

import { Col, Row } from 'reactstrap';

import WizardInput from '../../../components/auth/wizard/WizardInput';
import translate from '../../../helpers/translate';

const brandInputRegisterConfig = {
  required: translate('signup.free_user.brand.errors.required'),
  minLength: {
    value: 3,
    message: translate('signup.free_user.brand.errors.min_length'),
  },
  maxLength: {
    value: 50,
    message: translate('signup.free_user.brand.errors.max_length'),
  },
};

const projectInputRegisterConfig = {
  required: translate('signup.free_user.project.errors.required'),
  minLength: {
    value: 3,
    message: translate('signup.free_user.project.errors.min_length'),
  },
  maxLength: {
    value: 50,
    message: translate('signup.free_user.project.errors.max_length'),
  },
};

const SignUpFreeUserBrandProject = ({ register, errors }) => (
  <>
    <Row className="mb-2">
      <Col>
        <p className="fs-2 text-center">{translate('signup.free_user.project.copy_coverage')}</p>
        <p className="fs-2">{translate('signup.free_user.project.copy_project')}</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <WizardInput
          aria-label="brand-input"
          label={translate('signup.free_user.brand.label')}
          placeholder={translate('signup.free_user.brand.placeholder')}
          name="brand_name"
          id="brand_name"
          innerRef={register(brandInputRegisterConfig)}
          errors={errors}
        />
        <WizardInput
          aria-label="project-input"
          label={translate('signup.free_user.project.label')}
          placeholder={translate('signup.free_user.project.placeholder')}
          name="project_name"
          id="project_name"
          innerRef={register(projectInputRegisterConfig)}
          errors={errors}
        />
      </Col>
    </Row>
  </>
);

export default SignUpFreeUserBrandProject;
