import React, { useState } from 'react';

import ButtonIcon from '../../../components/common/ButtonIcon';
import translate from '../../../helpers/translate';
import TwitchFeedModalsKeyword from '../Modals/Keyword/TwitchFeedModalsKeyword.jsx';

const TwitchFeedEditFeedButton = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <ButtonIcon
        icon="pencil"
        transform="shrink-3 down-2"
        color="primary"
        size="sm"
        className="mx-2 d-none d-md-inline-block text-uppercase fp-button"
        onClick={() => setModalOpen(true)}
      >
        {translate('pages.twitch_feed.edit_feed')}
      </ButtonIcon>
      <TwitchFeedModalsKeyword isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default TwitchFeedEditFeedButton;
