import React, { useState } from 'react';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';

import ButtonIcon from '../../../components/common/ButtonIcon';
import SavedVideosSaveNewVideoModal from '../Modals/SaveNewVideo/SavedVideosSaveNewVideoModal.jsx';

const SavedVideosAddVideoButton = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClickButton = () => {
    setModalOpen(true);
  };

  return (
    <>
      <ButtonIcon icon={faPlusCircle} onClick={handleClickButton} color="primary" transform="shrink-3 down-2" size="sm">
        Save new Youtube video
      </ButtonIcon>
      <SavedVideosSaveNewVideoModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};

export default SavedVideosAddVideoButton;
