export const getArticlesFeed = (state) =>
  state.articlesFeed.data?.response?.data?.map((items) => ({
    ...items,
    media: items.url,
  }));

export const getArticlesFeedIsLoading = (state) => state.articlesFeed.data.isLoading;

export const getArticlesFeedPagination = (state) => {
  if (state.articlesFeed.data.response.pagination) {
    return {
      totalItems: state.articlesFeed.data.response.pagination.total_entries ?? null,
      lastPage: state.articlesFeed.data.response.pagination.last_page ?? null,
      page: state.articlesFeed.data.response.pagination.page_number ?? null,
      sizePerPage: state.articlesFeed.data.response.pagination.page_size ?? null,
    };
  }

  return [];
};

export const getArticlesFeedTableFilters = (state) => state.articlesFeed.filters;
export const getArticlesFeedSortField = state => state.articlesFeed.sortField;