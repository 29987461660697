import values from 'lodash/values';
import { connect } from 'react-redux';

import { columnsArticlesWebsite } from './helpers/columns';
import { tableRows } from './helpers/selectors';
import StaticDataTable from './StaticDataTable';
import { fetchArticles } from '../../../actions/articles';
import { extractFavicon, removeProtocolFromUrl } from '../../../config/url-utils';
import { getOrganizationPermissionsHasSimilarWeb } from '../../../selectors/permissions';
import { getCurrentProject } from '../../../selectors/projects';
import { getTag } from '../../../selectors/tags';

const mapStateToProps = (state, ownProps) => {
  const getDisplayTitleValue = (article) => {
    let title = article.title || removeProtocolFromUrl(article.url ? article.url : '');
    title = title.length > 85 ? `${title.substr(0, 85)}...` : title;
    return title;
  };
  const userHasSimilarWeb = getOrganizationPermissionsHasSimilarWeb(state);

  const articlesToRows = (articlesRaw) =>
    values(articlesRaw).map((article) => {
      const loadedTag = getTag(state, article.tag_id) || { name: '' };
      return {
        id: article.id,
        title: getDisplayTitleValue(article),
        tag: loadedTag.name,
        media: removeProtocolFromUrl(article.url),
        favIcon: extractFavicon(removeProtocolFromUrl(article.url)),
        muv: article['website-visits'],
        score: article.reach,
        language: article.language,
        date: article.date,
        fullUrl: article.fullUrl,
      };
    });
  return {
    state,
    currentProject: getCurrentProject(state),
    title: 'Articles',
    columns: columnsArticlesWebsite(userHasSimilarWeb),
    module: state.modules.modules.items.articlesWebsiteTable,
    rows: articlesToRows(tableRows(state, ownProps).articlesWebsiteTable()),
    ownProps: ownProps,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onLoad: () => {
    const { dispatch } = dispatchProps;
    dispatch(
      fetchArticles({
        page: { page: 1, page_size: 10000 },
        filter: {
          website_id: ownProps.websiteId,
        },
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(StaticDataTable);
