import { TWITCH_FEED_SET_SLACK_WEBHOOK, TWITCH_FEED_SET_SLACK_MIN_SUBSCRIBERS } from '../../actions/actionTypes';

const initialState = {
  slack_webhook_url: null,
  slack_min_subs_notification: null,
};

const slack = (state = initialState, action) => {
  switch (action.type) {
    case TWITCH_FEED_SET_SLACK_WEBHOOK:
      return { ...state, slack_webhook_url: action.payload };
    case TWITCH_FEED_SET_SLACK_MIN_SUBSCRIBERS:
      return { ...state, slack_min_subs_notification: action.payload };
    default:
      return state;
  }
};

export default slack;
