import React, { useState } from 'react';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';

import ButtonIcon from '../../../components/common/ButtonIcon';
import SavedStreamsSaveNewVideoModal from '../Modals/SaveNewStream/SavedStreamSaveNewStreamModal.jsx';

const SavedStreamsAddVideoButton = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleClickButton = () => {
    setModalOpen(true);
  };

  return (
    <>
      <ButtonIcon icon={faPlusCircle} onClick={handleClickButton} color="primary" transform="shrink-3 down-2" size="sm">
        Save new Twitch video
      </ButtonIcon>
      <SavedStreamsSaveNewVideoModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
};
export default SavedStreamsAddVideoButton;
