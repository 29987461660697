import {
  faChartArea,
  faChartLine,
  faGamepad,
  faGlobeAsia,
  faRss,
  faTag,
  faUser,
  faBookmark,
  faNewspaper,
} from '@fortawesome/free-solid-svg-icons';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';

export const homeRoutes = {
  name: 'Home',
  to: '/',
  projectSelect: true,
  exact: true,
  icon: 'chart-pie',
  children: [
    {
      to: '/',
      name: 'Articles',
      exact: true,
    },
    { to: '/dashboard-alt', name: 'Dashboard alt' },
    { to: '/feed', name: 'Feed', exact: true },
  ],
};

export const articleRoutes = {
  name: 'Articles',
  to: '/articles/:projectId',
  icon: faNewspaper,
};

export const feedRoute = {
  name: 'Media Feed',
  to: '/media-feed/:projectId',
  icon: faRss,
};

const youtubeFeedRoute = {
  name: 'Youtube',
  to: '/media-feed/youtube/:projectId',
};

const twitchFeedRoute = {
  name: 'Twitch',
  to: '/media-feed/twitch/:projectId',
};

const savedVideos = {
  name: 'Videos',
  to: '/videos/:projectId',
};

const mediaFeedRouteNew = {
  name: 'Articles',
  to: '/media-feed/:projectId',
};

const mediaFeedsRoute = {
  name: 'Media Feeds',
  children: [mediaFeedRouteNew, youtubeFeedRoute, twitchFeedRoute],
  icon: faRss,
};

export const savedArticles = {
  name: 'Articles',
  to: '/articles/:projectId',
};

const savedStreams = {
  name: 'Streams',
  to: '/streams/:projectId',
};

const savedCoverageRoute = {
  name: 'Saved Coverage',
  icon: faBookmark,
  children: [savedArticles, savedVideos, savedStreams],
};

export const alertRoutes = {
  name: 'Chart',
  to: '/alerts',
  icon: faChartArea,
};

const websitesRoute = {
  name: 'Websites',
  to: '/websites',
  icon: faGlobeAsia,
};

const brandsRoute = {
  name: 'Brands',
  to: '/brands/:projectId',
  icon: faUser,
};

const projectsRoute = {
  name: 'Projects',
  to: '/projects/:projectId',
  icon: faGamepad,
};

const tagsRoute = {
  name: 'Tags',
  to: '/tags/:projectId',
  icon: faTag,
};

const reportsRoute = {
  name: 'Reports',
  to: '/reports/:projectId',
  icon: faChartLine,
};

const superSearchRoute = {
  name: 'Super Search',
  to: '/supersearch/:projectId',
  icon: faSearch,
};

const faq = {
  name: 'FAQ',
  to: 'https://footprints.gg/faq/',
  icon: faQuestion,
};

const lateralMenu = [
  mediaFeedsRoute,
  savedCoverageRoute,
  reportsRoute,
  brandsRoute,
  projectsRoute,
  tagsRoute,
  websitesRoute,
  superSearchRoute,
  faq,
];

export default lateralMenu;
