import React, { useContext, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import {
  signupFreeUserCreateBrand,
  signupFreeUserCreateUser,
  signupFreeUserCreateProject,
  signupFreeUserCreateCustomer,
  signupFreeUserClear,
} from '../../../actions/signupFreeUser';
import { AuthWizardContext } from '../../../context/Context';
import translate from '../../../helpers/translate';
import {
  signupFreeUserAccountError,
  signupFreeUserClientData,
  signupFreeUserAccountData,
  signupFreeUserProjectData,
} from '../../../selectors/signupFreeUser';

const SignUpFreeUserResult = ({
  signupFreeUserCreateBrand,
  signupFreeUserCreateUser,
  signupFreeUserCreateProject,
  signupFreeUserCreateCustomer,
  signupFreeUserClear,
  projectData,
  clientData,
  accountData,
  errors,
}) => {
  const { setStep, setUser, user } = useContext(AuthWizardContext);
  const hasLoaded = useRef(false);

  const emptyData = () => {
    setStep(1);
    setUser({});
    signupFreeUserClear();
  };

  const { brand_name, organization_name, project_name, user_email, user_password, user_username } = user;

  useEffect(() => {
    if (!hasLoaded.current) {
      signupFreeUserCreateUser({
        name: user_username,
        email: user_email,
        password: user_password,
        organization_name,
      });
    }
  }, [user_username, user_email, user_password, signupFreeUserCreateUser, organization_name]);

  useEffect(() => {
    if (accountData && accountData['organization-id']) {
      signupFreeUserCreateBrand({ name: brand_name, org_id: parseInt(accountData['organization-id']) });
    }
  }, [brand_name, accountData, signupFreeUserCreateBrand]);

  useEffect(() => {
    if (accountData && accountData['organization-id'] && Object.values(clientData).length) {
      signupFreeUserCreateProject({
        name: project_name,
        organization_id: parseInt(accountData['organization-id']),
        client_id: parseInt(clientData.id),
      });
    }
  }, [signupFreeUserCreateProject, accountData, clientData, project_name]);

  useEffect(() => {
    if (
      accountData &&
      accountData['organization-id'] &&
      Object.values(clientData).length &&
      Object.values(projectData).length
    ) {
      signupFreeUserCreateCustomer({
        name: organization_name,
        email: user_email,
        organization_id: parseInt(accountData['organization-id']),
      });
    }
  }, [organization_name, user_email, signupFreeUserCreateCustomer, projectData, accountData, clientData]);

  if (errors) {
    return (
      <>
        <Row>
          <Col className="text-center">
            <h4 className="mb-1">{translate('signup.common.errors.copy')}</h4>
            <p className="fs-0">
              {translate('signup.common.errors.account_creation')}
              {errors.status}
            </p>
            <Button color="primary" className="px-5 my-3 text-white" aria-label="start-over" onClick={emptyData}>
              {translate('signup.common.errors.start_over')}
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  if (Object.values(accountData).length) {
    return (
      <>
        <Row className="d-flex px-md-6 pb-3">
          <Col className="align-content-center text-center">
            <Row className="justify-content-center">
              <h2>{translate('signup.free_user.result.title')}</h2>
            </Row>
            <Row className="mt-4 mb-4">
              <p className="fs-2 ">
                {translate('signup.free_user.result.copy')} {project_name}
              </p>
            </Row>
            <Row className="justify-content-center">
              <NavLink to="/">
                <Button color="primary" className="px-5" type="submit" transform="down-1 shrink-4" onClick={emptyData}>
                  {translate('signup.free_user.result.button')}
                </Button>
              </NavLink>
            </Row>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col className="text-center">
          <h4 className="mb-1">{translate('signup.free_user.last_step.creating_account')}</h4>
          <p className="fs-0">{translate('signup.free_user.last_step.wait_copy')}</p>
          <img
            width="70"
            src="https://ico-files.s3.eu-west-2.amazonaws.com/images/Footprints-Loading-Icon-70px.gif"
            alt="Loading"
          />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  errors: signupFreeUserAccountError(state),
  clientData: signupFreeUserClientData(state),
  accountData: signupFreeUserAccountData(state),
  projectData: signupFreeUserProjectData(state),
});

SignUpFreeUserResult.propTypes = {
  signupFreeUserCreateBrand: PropTypes.func,
  signupFreeUserCreateOrganization: PropTypes.func,
  signupFreeUserCreateUser: PropTypes.func,
  isLoading: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      errorCode: PropTypes.number,
      error: PropTypes.string,
    }),
  ),
};

export default connect(mapStateToProps, {
  signupFreeUserCreateBrand,
  signupFreeUserCreateUser,
  signupFreeUserCreateProject,
  signupFreeUserCreateCustomer,
  signupFreeUserClear,
})(SignUpFreeUserResult);
