import React, { useEffect } from 'react';

import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

import SingleSelect from '../../../../components/ICO/forms/SingleSelect';
import translate from '../../../../helpers/translate';

const validateTwitchUrl = url => {
  const twitchRegex = /^https:\/\/www.twitch.tv\//;
  return twitchRegex.test(url);
};

const SavedStreamaveNewStreamModalBody = ({
  setVideoURL,
  setIsUrlValid,
  setSelectedTags,
  videoURL,
  selectedTags,
  isUrlValid,
  tags
}) => {
  useEffect(
    () => () => {
      setIsUrlValid(false);
      setVideoURL(null);
      setSelectedTags([]);
    },
    [setIsUrlValid, setVideoURL, setSelectedTags]
  );

  const handleChangeInput = value => {
    if (value) {
      setVideoURL(value);
      setIsUrlValid(validateTwitchUrl(value));
    } else {
      setVideoURL(null);
      setIsUrlValid(false);
    }
  };

  const handleOnChangeTag = tag => {
    if (tag) {
      setSelectedTags(tag.value);
    } else {
      setSelectedTags(null);
    }
  };

  return (
    <FormGroup>
      <Row>
        <Col>
          <Label for="video">{translate('pages.saved_streams.save_new_stream.stream_label')}</Label>
          <Input
            onChange={event => handleChangeInput(event.target.value)}
            value={videoURL}
            id="video"
            placeholder={translate('pages.saved_streams.save_new_stream.twitch_video_placeholder')}
            valid={videoURL && isUrlValid}
            invalid={!isUrlValid}
          />
          {videoURL && !isUrlValid && (
            <div className="invalid-feedback">{translate('pages.saved_streams.save_new_stream.invalid_url')}</div>
          )}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Label for="tags">{translate('common.tags')}</Label>
          <SingleSelect
            name="tags"
            items={tags}
            value={selectedTags ?? []}
            className="sm-select"
            placeholder={translate('common.all_tags')}
            onChange={handleOnChangeTag}
          />
        </Col>
      </Row>
    </FormGroup>
  );
};

export default SavedStreamaveNewStreamModalBody;
