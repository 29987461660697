import isEqual from 'lodash/isEqual';

export const getTwitchFeed = (state) => state.twitchFeed.data?.response.data;

export const getTwitchFeedIsLoading = (state) => state.twitchFeed.data.isLoading;

export const getTwitchFeedPagination = (state) => {
  if (state.twitchFeed.data.response) {
    return {
      totalItems: state.twitchFeed.data.response.total,
      lastPage: state.twitchFeed.data.response.lastPage,
      page: state.twitchFeed.data.response.page,
    };
  }

  return [];
};
export const getTwitchFeedTableFiltersStartDate = (state) => state.twitchFeed.filters.startDate;
export const getTwitchFeedTableFiltersEndDate = (state) => state.twitchFeed.filters.endDate;
export const getTwitchFeedTableFilters = (state) => state.twitchFeed.filters;

export const getTwitchFeedSaveVideosModalIsOpen = (state) => state.twitchFeed.saveVideos.showModal;
export const getTwitchFeedCurrentKeyword = (state) => state.twitchFeed.data.response.keyword ?? null;

export const getTwitchFeedSlackWebhookURL = (state) => state.twitchFeed?.slack?.slack_webhook_url;
export const getTwitchFeedSlackWebhookMinSubscribers = (state) => state.twitchFeed?.slack?.slack_min_subs_notification;
export const getTwitchFeedDiscordWebhookURL = (state) => state.twitchFeed?.discord?.discord_webhook_url;
export const getTwitchFeedDiscordWebhookMinSubscribers = (state) =>
  state.twitchFeed?.discord?.discord_min_subs_notification;

export const getTwitchFeedUserHasConfiguredWebhook = (state) => !!state.twitchFeed?.data?.response?.webhook;
export const getTwitchFeedConfiguredWebhook = (state) => state.twitchFeed?.data?.response?.webhook;

export const getTwitchFeedAreNotificationsEnabled = (state) => state.twitchFeed?.areNotificationsEnabled;
export const getTwitchFeedIsFormEqual = (state) => {
  const original = state.twitchFeed.form.original;
  const modified = state.twitchFeed.form.modified;
  return isEqual(original, modified);
};
