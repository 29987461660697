import React from 'react';

import { Col, Row } from 'reactstrap';

import WizardInput from '../../../components/auth/wizard/WizardInput';
import translate from '../../../helpers/translate';

const organizationInputRegisterConfig = {
  required: translate('signup.free_user.organization.errors.required'),
  minLength: {
    value: 3,
    message: translate('signup.free_user.organization.errors.min_length'),
  },
  maxLength: {
    value: 30,
    message: translate('signup.free_user.organization.errors.max_length'),
  },
};

const SignUpFreeUserOrganization = ({ register, errors }) => (
  <>
    <Row>
      <Col>
        <p className="fs-2">{translate('signup.free_user.organization.copy')}</p>
      </Col>
    </Row>
    <Row>
      <Col>
        <WizardInput
          aria-label="organization-input"
          label={translate('signup.free_user.organization.label')}
          placeholder={translate('signup.free_user.organization.placeholder')}
          name="organization_name"
          id="organization_name"
          innerRef={register(organizationInputRegisterConfig)}
          errors={errors}
        />
      </Col>
    </Row>
  </>
);

export default SignUpFreeUserOrganization;
