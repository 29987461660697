import React, { useContext, useEffect } from 'react';

import { faGamepad } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalHeader } from 'reactstrap';

import ProjectsPanelBody from './ProjectsPanelBody';
import AppContext from '../../../context/Context';
import translate from '../../../helpers/translate';
import { createCookie, getCookieValue } from '../../../helpers/utils';
import ScrollBarCustom from '../../common/ScrollBarCustom';
import SettingsPanelBody from '../../side-panel/SettingsPanelBody';
import '../../../assets/scss/theme/Footprints/components/_side-panel.scss';

const SidePanelModalRoot = ({ autoShow, showOnce, autoShowDelay, cookieExpireTime, content }) => {
  const { isOpenSidePanel, toggleModal, isDark, setIsOpenSidePanel } = useContext(AppContext);

  const renderModalHeader = () => {
    if (content === 'settings') {
      return (
        <div className="py-1 flex-grow-1">
          <h5 className="text-white">
            <FontAwesomeIcon icon="palette" className="mr-2 fs-0" />
            Settings
          </h5>
          <p className="mb-0 fs--1 text-white opacity-75">Set your own customized style</p>
        </div>
      );
    }

    return (
      <div className="py-1 flex-grow-1">
        <h5>
          <FontAwesomeIcon icon={faGamepad} className="mr-2 fs-0" />
          {translate('sidePanelProjects.title')}
        </h5>
      </div>
    );
  };

  const renderModalBody = () => {
    if (content === 'settings') {
      return <SettingsPanelBody />;
    }
    return <ProjectsPanelBody />;
  };

  useEffect(() => {
    let modalStatus = getCookieValue('modalClose');

    if (modalStatus === null && autoShow) {
      setTimeout(() => {
        showOnce && createCookie('modalClose', false, cookieExpireTime);
      }, autoShowDelay);
    }
  }, [autoShow, showOnce, setIsOpenSidePanel, autoShowDelay, cookieExpireTime]);
  return (
    <Modal
      isOpen={isOpenSidePanel}
      toggle={toggleModal}
      modalClassName="overflow-hidden modal-fixed-left modal-theme fp-side-panel"
      className="modal-dialog-vertical"
      contentClassName="vh-100 border-0"
    >
      <ModalHeader tag="div" toggle={toggleModal} className="modal-header-settings">
        {renderModalHeader()}
      </ModalHeader>
      <ScrollBarCustom
        className="modal-body"
        contentProps={{
          renderer: ({ elementRef, ...restProps }) => (
            <span
              {...restProps}
              ref={elementRef}
              className={classNames('p-card position-absolute', { 'border-left': isDark })}
            />
          ),
        }}
      >
        {renderModalBody()}
      </ScrollBarCustom>
    </Modal>
  );
};

SidePanelModalRoot.propTypes = {
  autoShow: PropTypes.bool,
  showOnce: PropTypes.bool,
  autoShowDelay: PropTypes.number,
  cookieExpireTime: PropTypes.number,
  content: PropTypes.string,
};

SidePanelModalRoot.defaultProps = {
  autoShow: true,
  showOnce: true,
  autoShowDelay: 3000,
  cookieExpireTime: 7200000,
  content: 'SelectProject',
};

const mapStateToProps = (state) => ({
  content: state.forms.sidePanelModal.content,
});

export default connect(mapStateToProps)(SidePanelModalRoot);
