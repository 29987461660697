import React from 'react';

import { Col, Row } from 'reactstrap';

import getRandomGamingFact from '../../../helpers/getRandomGamingFact';
import translate from '../../../helpers/translate';

const ICOFooter = () => (
  <footer>
    <Row noGutters className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col sm="auto">
        <p className="mb-0 text-warning text-left">{translate('common.fact')}</p>
        <p className="mb-0 text-white text-left">{getRandomGamingFact()}</p>
      </Col>
      <Col sm="auto">
        <p className="mb-0 text-600 text-left">
          <br className="d-sm-none" /> {new Date().getFullYear()} &copy; Footprints
        </p>
        <p className="mb-0 text-600 text-left">v.1.12.10</p>
      </Col>
    </Row>
  </footer>
);

export default ICOFooter;
