import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import YoutubeFeedEditFeedModalBody from './YoutubeFeedEditFeedModalBody.jsx';
import YoutubeFeedEditFeedModalFooter from './YoutubeFeedEditFeedModalFooter.jsx';
import translate from '../../../../helpers/translate';
import { getYoutubeFeedCurrentKeyword } from '../../../../selectors/feeds/youtubeFeed';

const YoutubeFeedKeywordModal = ({ isOpen, onClose, currentKeyword }) => {
  const [keyword, setKeyword] = useState(null);

  useEffect(() => {
    if (currentKeyword) {
      setKeyword(currentKeyword);
    }
  }, [setKeyword, currentKeyword]);

  useEffect(() => () => setKeyword(null), [setKeyword]);

  return (
    <Modal isOpen={isOpen} toggle={onClose} onExit={onClose} className="fp-modal" size="lg">
      <ModalHeader className="fp-modal-header--small">{translate('pages.youtube_feed.edit_feed')}</ModalHeader>
      <ModalBody className="fp-modal-body">
        <YoutubeFeedEditFeedModalBody keyword={keyword} setKeyword={setKeyword} previousKeyword={currentKeyword} />
      </ModalBody>
      <ModalFooter>
        <YoutubeFeedEditFeedModalFooter onClose={onClose} keyword={keyword} previousKeyword={currentKeyword} />
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  currentKeyword: getYoutubeFeedCurrentKeyword(state),
});

export default connect(mapStateToProps, null)(YoutubeFeedKeywordModal);
