import { createSelector } from 'reselect';

export const signupFreeUser = (state) => state.signupFreeUser;

export const signupFreeUserClientData = createSelector([signupFreeUser], (signupData) => signupData.client.response);

export const signupFreeUserProjectData = createSelector([signupFreeUser], (signupData) => signupData.project.response);

export const signupFreeUserAccountData = createSelector([signupFreeUser], (signupData) => signupData.account.response);

export const signupFreeUserAccountError = createSelector([signupFreeUser], (signupData) => signupData.account.error);
