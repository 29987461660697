import React from 'react';

import values from 'lodash/values';
import { connect } from 'react-redux';

import CardsSimpleListTable from './CardsSimpleListTable';
import { tableRows } from './helpers/selectors';
import PurchasesTableActions from './PurchasesTableActions';
import { extractFavicon, removeProtocolFromUrl } from '../../../config/url-utils';
import { getCurrentProject } from '../../../selectors/projects';
import { getTag } from '../../../selectors/tags';

const mapStateToProps = (state, ownProps) => {
  const getDisplayTitleValue = (art) => {
    let title = art.title || removeProtocolFromUrl(art.url ? art.url : '');
    title = title.length > 85 ? `${title.substr(0, 85)}...` : title;
    return title;
  };
  const articlesToRows = (articlesRaw) =>
    values(articlesRaw).map((art) => {
      const loadedTag = getTag(state, art.tag_id) || { name: '' };
      return {
        id: art.id,
        title: getDisplayTitleValue(art),
        tag: loadedTag.name,
        media: removeProtocolFromUrl(art.url),
        url: art.url,
        favIcon: extractFavicon(removeProtocolFromUrl(art.url)),
        muv: art['website-visits'],
        score: art.reach,
        language: art.language,
        date: art.date,
        action: <PurchasesTableActions />,
        fullUrl: art.fullUrl,
      };
    });
  return {
    currentProject: getCurrentProject(state),
    module: ownProps.reportId
      ? state.modules.modules.items.articlesReportTable
      : state.modules.modules.items.articlesNewReportTable,
    rows: ownProps.reportId
      ? articlesToRows(tableRows(state, ownProps).articlesReportTable())
      : articlesToRows(tableRows(state).articlesNewReportTable()),
    ownProps: ownProps,
  };
};

export default connect(mapStateToProps)(CardsSimpleListTable);
