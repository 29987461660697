import values from 'lodash/values';

export const getClients = (state) => state.clients.clients.items;

export const getClient = (state, clientId) => {
  const clients = getClients(state);
  return clients[clientId] ? clients[clientId] : {};
};

export const getClientsLocked = (state, locked = true) => {
  let clients = values(getClients(state));
  return clients.filter((client) => {
    if (!locked) return !client.locked; //Just in case project.locked is null...

    return client.locked === locked;
  });
};

export const getClientsTableListStatusIsLoading = (state) =>
  Object.values(state.clients.clients.listsStatus)[0] === 'LOADING' && state.clients.clients.items.length;
