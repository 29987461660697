import React from 'react';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faLanguage } from '@fortawesome/free-solid-svg-icons/faLanguage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import getToastedNotification from '../../components/ICO/helpers/toaster.helper';

const MediaFeedAIErrorHandler = ({ message, onTranslate, onSentiment, row }) => {
  const customMessage = {
    type: 'error',
    message,
  };

  getToastedNotification({ customMessage });

  return (
    <div className="d-flex flex-row">
      <div onClick={onTranslate} className="cursor-pointer mr-2 d-flex flex-center">
        <FontAwesomeIcon icon={faLanguage} title="Translate this headline using AI" />
      </div>
      <div onClick={onSentiment} className="cursor-pointer mr-2 d-flex flex-center">
        <FontAwesomeIcon icon={faMagnifyingGlass} title="Get sentiment for this article using AI" />
      </div>
      {row.title}
    </div>
  );
};

export default MediaFeedAIErrorHandler;
