import { combineReducers } from 'redux';

import alerts from './alerts';
import articles from './articles/index';
import articlesFeed from './articlesFeed';
import clients from './clients';
import countries from './countries';
import feeds from './feeds';
import forms from './forms';
import languages from './languages';
import mediaTypes from './mediaTypes';
import modules from './modules';
import organizations from './organizations';
import organizationDetails from './organizations/details/index';
import products from './products';
import projects from './projects';
import regions from './regions';
import reports from './reports';
import savedStreams from './savedStreams';
import savedVideos from './savedVideos';
import settings from './settings/index';
import signupFreeUser from './signupFreeUsers';
import superSearch from './supersearch';
import tags from './tags/tags';
import tour from './tour';
import twitchFeed from './twitchFeed';
import upgradeSubscription from './upgradeSubscription';
import users from './users';
import websites from './websites';
import youtubeFeed from './youtubeFeed';
export default combineReducers({
  alerts,
  articles,
  articlesFeed,
  clients,
  feeds,
  forms,
  regions,
  languages,
  countries,
  mediaTypes,
  modules,
  organizationDetails,
  organizations,
  products,
  projects,
  reports,
  settings,
  signupFreeUser,
  superSearch,
  tags,
  upgradeSubscription,
  users,
  websites,
  tour,
  youtubeFeed,
  savedVideos,
  twitchFeed,
  savedStreams,
});
