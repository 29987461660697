import { values } from 'lodash';

import { getCurrentProjectId } from './forms';

const sortByUpdatedAt = (tagA, tagB) => new Date(tagB['updated-at']) - new Date(tagA['updated-at']);

export const getTags = (state) => state.tags.items;
export const getTag = (state, tagId) => {
  const tags = getTags(state);
  if (tags) {
    return tags[tagId];
  } else {
    return {
      name: 'Loading...',
      id: -1,
    };
  }
};

export const filterTagsByProjectId = (state, projectId) => {
  const tags = getTags(state);
  return values(tags).filter((tag) => tag.project_id === projectId);
};

export const getTagOptions = (state) => getTagsLocked(state, false).sort(sortByUpdatedAt);

export const getTagByKey = (state, key) => (state.tags.items[key] ? state.tags.items[key] : {});

export const getTagsLocked = (state, locked = true) => {
  const currentProjectId = getCurrentProjectId(state);
  let tags = filterTagsByProjectId(state, currentProjectId);
  return tags.filter((tag) => {
    if (!locked) return !tag.locked; //Just in case project.locked is null...

    return tag.locked === locked;
  });
};

export const getTagsTableListStatusIsLoading = (state) => Object.values(state.tags.listsStatus)[1] === 'LOADING';
export const getTagsAddTagModalIsVisible = (state) => state.forms.modal.isVisible;
export const getTagsAddTagModalFormFields = (state) => (state.forms.newTagForm ? state.forms.newTagForm.fields : {});
export const getTagsAddTagModalFormTitle = (state) => (state.forms.newTagForm ? state.forms.newTagForm.title : '');
export const getNotLockedTags = (state) => {
  const currentProjectId = getCurrentProjectId(state);
  const tagsByProject = filterTagsByProjectId(state, currentProjectId);
  return tagsByProject.filter((tag) => !tag.locked).map((tag) => ({ value: tag.id, label: tag.name }));
};
