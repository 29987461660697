import { combineReducers } from 'redux';

import {
  REQUEST_PROJECTS,
  RECEIVE_PROJECTS,
  REJECT_PROJECTS,
  REQUEST_SAVE_PROJECT,
  RECEIVE_SAVE_PROJECT,
  REJECT_SAVE_PROJECT,
} from '../actions/projects';
import { apiReceive, apiRequest, apiReject } from '../config/api-service';

const base = (
  state = {
    items: {},
    itemsStatus: {},
    lists: {},
    listsStatus: {},
    stats: {},
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_PROJECTS:
    case REQUEST_SAVE_PROJECT:
      return apiRequest(state, action);
    case RECEIVE_PROJECTS:
    case RECEIVE_SAVE_PROJECT:
      return apiReceive(state, action);
    case REJECT_PROJECTS:
    case REJECT_SAVE_PROJECT:
      return apiReject(state, action);
    default:
      return Object.assign({}, state);
  }
};

export default combineReducers({
  projects: base,
});
